import moment             from 'moment';
import store              from '@/store';
import {isUser, isBureau} from '@/util/user';

export const momentDates = data => Array.isArray(data) ? data.map(momentDates) : {
    ...data,
    date: moment(data.date.date)
};

export const canDeleteCommentaire = (commentaire, user = store.state.user.utilisateur) =>
    isUser(user) && (isBureau(user) || commentaire.utilisateur.id === user.id);

export default {momentDates, canDeleteCommentaire};