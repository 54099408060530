<template>
    <div v-if="evenement">
        <cluji-card no-header class="text-center"
                    :no-header="isCollapseShown"
                    :title="evenement.nom"
                    :subtitle="format2Dates(evenement.dateDebut, evenement.dateFin)"
                    @collapse="updateCollapse">
            <evenement-infos :evenement="evenement" @edit="loadData"/>
        </cluji-card>
        <cluji-card title="Participants">
            <evenement-participations :evenement="evenement" @edit="loadData"/>
        </cluji-card>
        <cluji-card title="Déroulement" :starts-open="evenement.etapes.length" v-if="areEtapesHydrated">
            <evenement-timeline :evenement="evenement" @updateEtapes="hydrateEtapes"/>
        </cluji-card>
        <cluji-card title="Commentaires" :starts-open="evenement.commentaires.length" v-if="areCommentairesHydrated">
            <evenement-commentaires :evenement="evenement" @updateCommentaires="hydrateCommentaires"/>
        </cluji-card>
    </div>
</template>

<script>
    const EvenementCommentaires   = () => import('@/components/evenement/EvenementCommentaires');
    const EvenementInfos          = () => import('@/components/evenement/EvenementInfos');
    const EvenementTimeline       = () => import('@/components/evenement/EvenementTimeline');
    const EvenementParticipations = () => import('@/components/evenement/EvenementParticipations');
    const ClujiCard               = () => import('@/components/ClujiCard');

    import {apiPath}      from '@/util/http';
    import alert          from '@/util/alert';
    import {format2Dates} from '@/util/date';
    import demande        from '@/util/demande';
    import etapeEvenement from '@/util/etapeEvenement';
    import commentaire    from '@/util/commentaire';

    export default {
        name: "Evenement",
        components: {
            EvenementParticipations,
            EvenementCommentaires,
            EvenementInfos,
            EvenementTimeline,
            ClujiCard
        },
        data: () => ({
            evenement: null,
            isCollapseShown: true,
            areCommentairesHydrated: false,
            areEtapesHydrated: false
        }),
        methods: {
            format2Dates,
            updateCollapse(val) {
                this.isCollapseShown = val;
            },
            hydrateCommentaires() {
                alert.loading();
                this.areCommentairesHydrated = false;
                return this.axios.get(apiPath('commentaires_evenement', {evenement: this.evenement.id}))
                    .then(response => {
                        this.evenement.commentaires  = commentaire.momentDates(response.data);
                        this.areCommentairesHydrated = true;
                    })
                    .catch(() => this.$toaster.error("Impossible de charger les commentaires"))
                    .finally(alert.stopLoading);
            },
            hydrateEtapes() {
                alert.loading();
                this.areEtapesHydrated = false;
                return this.axios.get(apiPath('list_etapes_for_evenement', {evenement: this.evenement.id}))
                    .then(response => {
                        this.evenement.etapes  = etapeEvenement.momentDates(response.data);
                        this.areEtapesHydrated = true;
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des étapes'))
                    .finally(alert.stopLoading);
            },
            loadData() {
                alert.loading();
                this.areCommentairesHydrated = false;
                this.areEtapesHydrated       = false;
                return this.axios.get(apiPath('evenement_detail', {evenement: this.$route.params.eventId}))
                    .then(response => this.evenement = demande.momentDates(response.data))
                    .then(() => this.hydrateCommentaires())
                    .then(() => this.hydrateEtapes())
                    .catch(() => this.$toaster.error("Impossible de charger le détail de l'événement"))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
